.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  
  .txt-align-left {
    text-align: left;
  }
  
  .padding-left2em {
    padding-left: 2em;
  }
  
  .search-bar {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .button-group {
    justify-content: center;
    margin: 20px 0;
  }
  
  .toggle-button {
    background-color: #009fb3;
    color: white;
    /* padding: 10px 20px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    padding: 10px;
  }
  
  .toggle-button.active {
    background-color: #7495b8;
  }
  
  .toggle-button:hover {
    background-color: #0056b3;
  }

  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
  }
  
  .multi-select {
    width: 100%;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  .card {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 16px 0;
    padding: 20px;
  }
  
  .card-color {
    color: #6294c9;
  }
  
  .txt-decoration-none {
    text-decoration: none;
  }
  
  .tags {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .tag {
    display: inline-flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .tag-label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .tag-votes {
    border-radius: 10px;
    padding: 2px 6px;
    color: #fff;
  }
  
  .low-votes {
    background-color: #ff76006e; 
  }
  
  .medium-votes {
    background-color: #00cc66;
  }
  
  .high-votes {
    background-color: #ffd700;
  }
  
  .card-home:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
} 

 .card-home::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.1);  */
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; 
} 

 .card-home:hover::after {
  opacity: 1;
}

.clickable-text {
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.clickable-text:hover {
  color: #007BFF; /* Change text color on hover */
  background-color: #E9F5FF; /* Add a light background color on hover */
}