
  .google-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .login-label {
    margin-left: auto;
  }
  
  .zindex-high {
    z-index: 10;
  }
  

  .google-login-card {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
  
  .google-login-button {
    padding: 10px 20px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .google-login-button:hover {
    background-color: #357ae8; /* Optional: A slight hover effect */
  }
  