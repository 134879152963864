.fancy-alert {
    background-color: #ffcc00;
    color: #333;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fancy-alert.fade-out {
    opacity: 0;
  }
  