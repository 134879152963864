.comment {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 12px;
    border-bottom: 1px solid #ccc;
  }
  
  .comment p {
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .comment-icons {
    display: flex;
    align-items: baseline;
    /* gap: 10px; */
    margin-left: auto;
    cursor: pointer;
  }
  
  .comment-form textarea {
    width: 98%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
  }
  
  .comment-form button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .comment-form button:hover {
    background-color: #0056b3;
  }
  
  .comment textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .comment button {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .comment button:hover {
    background-color: #218838;
  }
  
  .comment small {
    display: block;
    margin-top: 5px;
    color: #8c8c8c;
  }
  
  .font14px{
    font-size: 14px !important;
  }

  .font16px{
    font-size: 16px !important;
  }

  .font-small{
    font-size: small !important;
  }

  .comment-list{
    margin-top: 1em;
  }