body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f8ff;
    margin: 0;
    padding: 0;
    color: #333;
}

.header-land {
    color: #406789;
    padding: 20px;
    text-align: center;
    font-family: cursive;
    font-size: 24px;
    font-weight: bold;
}

.container-land {
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    max-width: 1200px;
    width: 90%;
}

.container-land h1 {
    font-size: 2.25rem; /* Approximately 36px */
    margin-bottom: 20px;
    color: #4c6b8b;
}

.container-land p {
    font-size: 1.125rem; /* Approximately 18px */
    line-height: 1.6;
    margin-bottom: 40px;
}

.button-container {
    margin: 30px 0;
}

.button-land {
    background-color: #28a745;
    color: white;
    padding: 15px 30px;
    font-size: 1.125rem; /* Approximately 18px */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
}

.button-land:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.features-land {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    margin-top: 50px;
    text-align: left;
}

.feature-box-land {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 22px;
    width: 100%;
    max-width: 290px;
    transition: transform 0.3s ease;
    margin: 16px auto;
}

/* @media (min-width: 768px) {
    .feature-box-land {
        width: 45%;
    }
}

@media (min-width: 1024px) {
    .feature-box-land {
        width: 30%;
    }
} */

.feature-box-land:hover {
    transform: translateY(-10px);
}

.feature-box-land h2 {
    font-size: 1.5rem; /* Approximately 24px */
    color: #386798;
    margin-bottom: 15px;
}

.feature-box-land p {
    font-size: 1rem; /* Approximately 16px */
    line-height: 1.5;
}

.footer-land {
    color: #7b7272;
    padding: 20px;
    text-align: center;
    width: 100%;
    font-size: 0.875rem; /* Approximately 14px */
}

.footer-land a {
    color: #007BFF;
    text-decoration: none;
}

.footer-land a:hover {
    text-decoration: underline;
}

.sde-logo {
    display: block;
    margin: 20px auto;
    width: 50%;
    max-width: 329px;
    height: auto;
}
