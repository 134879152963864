
  
  .txt-decoration-none {
    text-decoration: none;
  }
  
  .tags {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Add the styles from Tag.css or import it directly if it's separate */
  .tag {
    display: inline-flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .tag-label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .tag-votes {
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    padding: 2px 6px;
  }

.card .tags {
  margin-top: 8px;
}

.card .drawing {
  margin-top: 16px;
}

.card img {
  max-width: 100%;
  height: auto;
}

.card .drawing {
  margin-left: 20px;
}

.card img {
  max-width: 200px;
  height: auto;
}

.block-tag{
  display: block !important;
}

.quest-on-top{
  display: flex;
  color: #808080;
  padding: 0.2em;
  cursor: pointer;
  font-size: 14px;
  z-index: 1000;
  font-weight: 700;
}

.m05em{
  margin-right: 0.5em;
}

.display-flex{
  display: flex;
}

.quest-on-top:hover{
  color:rgb(218, 163, 44) !important;
}

.tags-header-label{
  white-space: nowrap;
  margin-top: 13px;
}

.label-small{
  border: 1px solid;
  border-radius: 8px;
  background-color: #62b1e6;
  font-size: 12px;
  padding: 3px;
  color: white;
  padding: 5px;
}

