.card-full-answer {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 0;
  cursor: default !important;
  /* display: grid; */
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: start;
  position: relative;
}

.tags-container {
  grid-column: 1;
  grid-row: 1;
  margin-right: 20px;
}
/* .content{
  display: flex;
} */

.content100 {
  width: 100%;
}

.content-container {
  grid-column: 2;
  grid-row: 1;
}

.title {
  margin-bottom: 10px;
}

.drawing {
  text-align: center;
  padding: 2em 0;
}

.body {
  margin-top: 10px;
}

.tags {
  display: flex;
  flex-direction: row;
}

.tag-item {
  margin-bottom: 10px;
}

.betteridea{
  font-size: 13px;
  color: #412c2e;
  font-weight: bold;
}
.underline{
  text-decoration: underline;
}