.search-bar {
    display: flex;
    justify-content: center;
  }
  
  .search-bar input {
    /* width: 338px; */
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .search-bar input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  

  .search-bar {
    position: relative;
  }
  
  .search-results-overlay {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 300px; /* Limit the height */
    overflow-y: auto;  /* Scroll if results exceed max-height */
  }
  
  .search-result-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .search-result-item:last-child {
    border-bottom: none;
    width: 21em;
  }
  
  .search-result-item:hover {
    background-color: #f9f9f9;
  }
  
  .search-result-item h4 {
    margin: 0;
    font-size: 16px;
  }
  
  .search-result-item p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #555;
  }
  .search-result-name{
    color:#505356 !important;
  }
  