/* App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.navbar {
  background-color: rgb(78, 154, 217);
  padding: 15px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}


.navbar a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 18px;
}

.navbar a:hover {
  text-decoration: underline;
}

.header, .footer {
  text-align: center;
  padding: 20px 0;
}

.login-label{
  border-radius: 14px;
  background-color: #3972b3;
  padding: 7px;
  font-size: 14px !important;
  cursor: pointer;
  font-family: 'Assistant';
}



.footer {
  background-color: #333;
  color: #fff;
  margin-top: 20px;
}


button {
  background-color: rgb(78, 154, 217);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: rgb(58, 134, 197);
}

.quest-label{
padding:1em;
}

.txtarea{
  width: 63em;
  height: 22em;
  resize: none;
  margin-bottom: 1em;
  background-color: white;
}

.title-quest{
  width: 38em;
  height: 3em;
}

.system-design-label{
  color: #fff;
  text-decoration: none;
  /* margin: 0 15px; */
  font-size: 14px;
  background-color: rgb(78, 154, 217);
  padding: 1em;
}

.save-design{
  text-align: center;
  margin-top: 1em;
}

.txt-align-left{
  text-align: left;
}

.padding-left2em{
  padding-left: 2em;
}

.txt-decoration-none{
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: rgb(78, 154, 217);
  color: #fff;
  margin-bottom: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.google-signin-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #4285f4;
  color: #4285f4;
  padding: 10px 20px;
  border-radius: 14px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-signin-button:hover {
  background-color: #f5f5f5;
}

.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.display-flex{
  display: flex;
}

.posted-label{
  color: #80868a;
  margin-left: 7px;
}

.button-group{
  display: inline-flex;
}

.marginTop24{
  margin-top: 24px;
}

.postedby-color{
  color: #797979;
}

.beta-tag {
  top: 10px;
  right: 10px;
  background-color: #3db0dc;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 14px;
  z-index: 1000;
  height: 16px;
  margin-top: 0.6em;
  margin-right: 8px;
}

.layer-ui__wrapper__top-right{
  display: none;
}

.ql-editor p{
  font-size: 15px !important;
}