.user-profile {
    padding: 20px;
  }
  
  .user-info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  
  .user-content {
    margin-bottom: 20px;
  }
  
  .user-question, .user-answer {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .icons {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  

  :-moz-ui-invalid

  .user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .user-content-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  
  .user-questions-column, .user-answers-column {
    width: 45%;
  }
  
  .user-question, .user-answer {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .icons {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }