.criteria{
    font-size: small;
    font-family: sans-serif;
    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
}

.add-design-button {
    flex: 0 0 auto;
    margin-left: auto; /* This will push the button to the extreme right */
  }

  .load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .load-more-button:hover {
    background-color: #0056b3;
  }
  