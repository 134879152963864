.ask-question {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    max-width: 600px;
    margin: auto;
    margin-top: 2em;
  }
  
  .ask-question h2 {
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-group input {
    width: 97%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
  }
  
  .multi-select {
    width: 100%;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #ff3860; /* Red color for errors */
    font-size: 0.9rem; /* Slightly smaller font size */
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    display: block;
    font-weight: 500;
    background-color: #ffe3e3; /* Light red background for better visibility */
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ff3860; /* Border matching the text color */
    box-shadow: 0px 0px 5px rgba(255, 56, 96, 0.5); /* Subtle shadow for a 3D effect */
    animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }