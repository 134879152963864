
 .navbar-top {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }


  .navbar-right{
    margin-left: auto; /* Ensures the content moves to the extreme right */
    text-align: right; /* Optional: Aligns text or inline elements to the right */
  }
 
  .zindex-high{
    z-index: 999 !important;
  }

  .dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    width: 150px;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 8px 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #2d3030 !important;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  
  